import { useRef, useState } from 'react'

import {
  IconButton,
  Box,
  List,
  ListItem,
  Divider,
  Typography,
  ListItemText,
  Popover,
  Tooltip,
  styled,
} from '@mui/material'
import Text from 'src/components/Text'

import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone'
import internationalization from 'src/i18n/i18n'
import { useTranslation } from 'react-i18next'

import { DE } from 'country-flag-icons/react/3x2'
import { US } from 'country-flag-icons/react/3x2'
import { ES } from 'country-flag-icons/react/3x2'
import { FR } from 'country-flag-icons/react/3x2'
import { CN } from 'country-flag-icons/react/3x2'
import { AE } from 'country-flag-icons/react/3x2'

const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`
)

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};

        svg {
          width: 28px;
        }
`
)

function LanguageSwitcher() {
  const { i18n } = useTranslation()
  const { t } = useTranslation()
  const getLanguage = i18n.language

  const switchLanguage = ({ lng }) => {
    internationalization.changeLanguage(lng)
  }
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip arrow title={t('Language Switcher')}>
        <IconButtonWrapper color="success" ref={ref} onClick={handleOpen}>
          {getLanguage === 'de' && <DE title="German" />}
          {getLanguage === 'en' && <US title="English" />}
          {getLanguage === 'en-US' && <US title="English" />}
          {getLanguage === 'en-GB' && <US title="English" />}
          {getLanguage === 'es' && <ES title="Spanish" />}
          {getLanguage === 'fr' && <FR title="French" />}
          {getLanguage === 'cn' && <CN title="Chinese" />}
          {getLanguage === 'ae' && <AE title="Arabic" />}
        </IconButtonWrapper>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            maxWidth: 240,
          }}
        >
          <SectionHeading variant="body2" color="text.primary">
            {t('Language Switcher')}
          </SectionHeading>
          <List
            sx={{
              p: 2,
              svg: {
                width: 26,
                mr: 1,
              },
            }}
            component="nav"
          >
            <ListItem
              className={
                getLanguage === 'en' || getLanguage === 'en-US' ? 'active' : ''
              }
              button
              onClick={() => {
                switchLanguage({ lng: 'en' })
                handleClose()
              }}
            >
              <US title="English" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary="English"
              />
            </ListItem>
            <ListItem
              className={getLanguage === 'de' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'de' })
                handleClose()
              }}
            >
              <DE title="German" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary="German"
              />
            </ListItem>
            <ListItem
              className={getLanguage === 'es' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'es' })
                handleClose()
              }}
            >
              <ES title="Spanish" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary="Spanish"
              />
            </ListItem>
            <ListItem
              className={getLanguage === 'fr' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'fr' })
                handleClose()
              }}
            >
              <FR title="French" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary="French"
              />
            </ListItem>
            <ListItem
              className={getLanguage === 'cn' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'cn' })
                handleClose()
              }}
            >
              <CN title="Chinese" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary="Chinese"
              />
            </ListItem>
            <ListItem
              className={getLanguage === 'ae' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'ae' })
                handleClose()
              }}
            >
              <AE title="Arabic" />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary="Arabic"
              />
            </ListItem>
          </List>
          <Divider />
          <Text color="warning">
            <Box
              p={2}
              sx={{
                maxWidth: 340,
              }}
            >
              <WarningTwoToneIcon fontSize="small" />
              <Typography variant="body1">
                {t(
                  'We only translated a small part of the template, for demonstration purposes'
                )}
                !
              </Typography>
            </Box>
          </Text>
        </Box>
      </Popover>
    </>
  )
}

export default LanguageSwitcher
